/* Default styles */
.myTable {
    width: 100%;
    margin: 0 20px;
  }
  
  /* Media Queries for Responsive Design */
  @media (max-width: 768px) {
    .myTable {
      margin: 0 10px;
    }
  }
  