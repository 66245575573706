/* Default styles */
.promotions {
  margin: 50px;
}

.promoHeading {
  padding-bottom: 30px;
}

.MyFloatingLabel {
  padding-bottom: 30px;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .promotions {
    margin: 30px;
  }

  .promoHeading,
  .MyFloatingLabel {
    padding-bottom: 20px;
  }
}
