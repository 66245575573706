.myNavbar{
    background-color:wheat;
    padding: 10px 20px;
    align-items: baseline;
}

.myNav{
    justify-content: space-between;
}

/************************************************************************/

.nav-link-item {
    color: #333;
    padding: 10px 15px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .nav-link-item:hover {
    color: #007bff; 
  }
  .nav .nav-link-item {
    color: #333; 
  }
  
  .nav .nav-link-item:hover {
    color: #007bff; 
  }
  .navbar-brand img {
    max-height: 50px;
  }
  .myNavbar {
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd; 
  }
  .myNav {
    margin-top: 10px;
  }
  .myNavDropdown{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }